/* eslint-disable prettier/prettier */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import ctoBannerBg from "../images/v2/campaigns/cto-banner-bg.svg";
import bannerDot from "../images/v2/campaigns/banner-dot.svg";
import ctoLogo from "../images/v2/campaigns/cto-logo.png";
import ctoBannerImg from "../images/v2/campaigns/cto-banner-img.png";
import bannerTick from "../images/v2/GreenTick.png";
import digitalAd from "../images/v2/campaigns/digital-ad.svg";
import projectManagement from "../images/v2/campaigns/projectManagement.svg";
import digitalSuccessBg from "../images/v2/campaigns/digital-success-bg.svg";

import digitalSuccess from "../images/v2/campaigns/digital-success.png";

import pepperCloudCRMHelpsContentBg from "../images/v2/campaigns/crm-helps-content-bg.svg";

import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import WhiteButton from "../components/v2/WhightButton";
import SEO from "../components/SEO";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  singaporeSmeBanner: {
    background: `url(${ctoBannerBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    paddingTop: "60px",
    overflow: "hidden",
    position: "relative",
  },
  bannerPoints: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "0",
    "@media(max-width:750px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  bannerSection: {
    position: "relative",
  },
  bannerPointItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "28px",
    "&:nth-child(1)": {
      paddingLeft: "0",
    },
    "@media(max-width:750px)": {
      paddingLeft: "0",
    },
  },
  bannerPointcontent: {
    paddingLeft: "6px",
    fontSize: "20px",
    color: "#000",
    fontFamily: "'SourceSansPro', sans-serif",
  },
  bannerLeft: {
    minHeight: "500px",
    "@media (max-width: 960px)": {
      minHeight: "auto",
    },
  },
  salesProcessCard: {
    border: "none",
    boxShadow: "none",
    // backgroundColor: "transparent",
    marginBottom: theme.spacing(4),
  },

  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    background: `url(${pepperCloudCRMHelpsContentBg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pepperCloudCRMHelpsContentListBox: {
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#ffffff",
    padding: "2rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "1rem",
    marginRight: "1rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
    "&:not(:last-of-type)": {
      borderRight: "1px solid rgba(112, 112, 112, .5)",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    fontSize: "36px",
    color: "#088937",
    paddingRight: "1rem",
    lineHeight: "normal",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  bannerTopHeader: {
    fontSize: "18px",
    color: "#FF7A59",
    marginBottom: "16px",
  },
  aboutCto: {
    marginBottom: "60px",
  },
  aboutCtoinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "cneter",
    flexDirection: "column",
    color: "#000",
  },
  offerBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #dcdcdc",
    borderRadius: "4px",
    padding: "24px 0 0",
    margin: "0 12px 0 0",
  },
  writeToUsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0",
  },
  digitalSuccessBlock: {
    background: `url(${digitalSuccessBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  faqTitle: {
    color: "#000",
    display: "flex",
    fontSize: 20,
    marginTop: 2,
    fontWeight: 600,
    marginBottom: 2,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBox: {
    paddingBottom: "45px",
  },
  faqAnswer: {
    color: "#000",
    fontSize: 18,
    marginTop: 8,
    maxWidth: 920,
    width: "calc( 100% - 50px )",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  expendButtonFaq: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  requestDemoRootWidth: {
    width: "931px",
    paddingLeft: "315px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px",
    },
  },
  collapseDivider: {
    backgroundColor: "#000",
    opacity: "0.15",
  },
  bannerDotImg: {
    position: "absolute",
    top: "35%",
    right: "-5%",
    "@media (max-width:960px)": {
      display: "none",
    },
  },
  CtoOffer: {
    borderTop: "1px solid #707070",
  },
  bannerImageBox: {
    position: "relative",
  },
  bannerImageinner: {
    position: "absolute",
    top: "0",
    right: "-10%",
    height: "380px",
    "@media(max-width:1300px)": {
      top: "80px",
      right: "2%",
      height: "320px",
    },
    "@media(max-width:1200px)": {
      top: "84px",
      right: "-1%",
      height: "320px",
    },
    "@media(max-width:1100px)": {
      top: "0px",
      right: "-1%",
      height: "320px",
    },
    "@media(max-width:960px)": {
      position: "static",
      height: "400px",
      padding: "20px 0",
      marginTop: "32px",
    },
  },
  digitalSucessGridInner: {
    position: "relative",
  },
  offerrSection: {
    alignItems: "flex-start",
    color: "#000",
  },
  digitalSucessGridInnerImg: {
    position: "absolute",
    top: "24px",
    right: "-10%",
    "@media(max-width:1440px)": {
      right: "-3%",
      top: "60px",
      width: "90%",
    },
    "@media(max-width:1300px)": {
      right: "0",
      top: "60px",
      width: "90%",
    },
    "@media(max-width:960px)": {
      position: "static",
      marginTop: "24px",
    },
  },
}));

const CtoAsAService = () => {
  const [open, setOpen] = React.useState(0);
  const [activePipeline, setActivePipeline] = React.useState(0);

  const pipeline = [
    {
      title: "Built for SMEs",
      description:
        "Pepper Cloud understands the priorities and challenges of SMEs like yours. To address your needs and drive your business growth, we offer a sales CRM system designed exclusively for SMEs.",
    },
    {
      title: "Industry expertise",
      description:
        "Leveraging our extensive experience and deep industry knowledge, we foster SME success through a tailored CRM solution. From start-up to scale-up, Pepper Cloud is a reliable partner for growing SMEs.",
    },
    {
      title: "All-in-one sales CRM",
      description:
        "Seamlessly integrate with popular platforms like WhatsApp, Facebook Messenger, Mailchimp, MailerLite, Xero, Zoom, and more. Enhance your sales efficiency with the comprehensive CRM solution.",
    },
    {
      title: "Sales automation and digitalisation",
      description:
        " Transform your business with the best sales CRM.  Seamlessly digitalise sales activities and put your growth on auto-pilot mode. Automate workflows, minimise manual errors and enhance your overall efficiency.",
    },
    {
      title: "24/7 support and guidance",
      description:
        "Get reliable 24/7, one-to-one support from Pepper Cloud. Equip your team with the required knowledge and skills and leverage the software for your sales success.",
    },
  ];

  const handleClickCollapse = (index) => () => {
    setOpen((res) => (res === index ? -1 : index));
  };
  const classes = useStyles();

  const width = useWidth();
  return (
    <React.Fragment>
      {/* Header  */}
      {/* Banner */}

      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid
            alignItems="flex-start"
            className={classes.bannerSection}
            container
            justify="center"
            spacing={2}
          >
            <Grid className={classes.bannerLeft} item md={6} sm={12}>
              <Box>
                <HeaderTypography
                  className={classes.bannerTopHeader}
                  component="h2"
                >
                  CTO-AS-A-SERVICE
                </HeaderTypography>
                <HeaderTypography
                  className={classes.bannerHeader}
                  fontSize={{ sm: 36, xs: 34 }[width] || 40}
                  lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
                  mt={4}
                  my={2}
                  overrideClassName
                  component="h1"
                >
                  Digitalise your sales <br /> with CTOaaS
                </HeaderTypography>
                <ParagraphTypography
                  color="#000"
                  fontSize={18}
                  fontWeight={400}
                  mb={3}
                  mt={0}
                >
                  Leverage CTOaaS, unlock your full sales potential, and
                  digitalise your business operations with Singapore’s best
                  sales CRM.
                </ParagraphTypography>
                <Box className={classes.bannerPoints}>
                  <Box className={classes.bannerPointItem}>
                    <Box component={"img"} height={38} src={bannerTick} />
                    <ParagraphTypography className={classes.bannerPointcontent}>
                      Built for SMEs
                    </ParagraphTypography>
                  </Box>
                  <Box className={classes.bannerPointItem}>
                    <Box component={"img"} height={38} src={bannerTick} />
                    <ParagraphTypography className={classes.bannerPointcontent}>
                      Sales digitalisation
                    </ParagraphTypography>
                  </Box>
                  <Box className={classes.bannerPointItem}>
                    <Box component={"img"} height={38} src={bannerTick} />
                    <ParagraphTypography className={classes.bannerPointcontent}>
                      1:1 Personal support
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} className={classes.bannerImageBox}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                <Box
                  component={"img"}
                  height={400}
                  position={"relative"}
                  zIndex={10}
                  src={ctoBannerImg}
                  className={classes.bannerImageinner}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className={classes.bannerDotImg}>
          <Box component={"img"} src={bannerDot} />
        </Box>
      </Box>
      {/* Content*/}
      <Box className={classes.aboutCto}>
        <Container>
          <Box className={classes.aboutCtoinner}>
            <HeaderTypography
              className={classes.bannerHeader}
              fontSize={{ sm: 36, xs: 34 }[width] || 44}
              fontWeight={600}
              lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
              mt={4}
              my={2}
              overrideClassName
              textAlign="center"
              component="h2"
            >
              About CTOaaS
            </HeaderTypography>
            <ParagraphTypography
              color="#000"
              fontSize={18}
              fontWeight={400}
              lineHeight={"26px"}
              mb={6}
              mt={0}
              textAlign="center"
            >
              As part of Singapore's SME Go Digital initiative, the Chief
              Technology Officer-as-a-Service (CTO-aaS) program assists SMEs in
              Singapore. It appoints consultants to assess digital readiness,
              identify needs, and address digital gaps in SMEs.{" "}
              <a href="https://services2.imda.gov.sg/CTOaaS/Solution/20230105/pepper-cloud-crm">
                Pepper Cloud
              </a>{" "}
              stands as a reliable CTO-aaS provider, specialising in sales and
              customer relationship management, actively engaging with SMEs in
              their journey of digital transformation.
            </ParagraphTypography>
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "0",
              }}
            >
              <Box maxWidth={1015} textAlign="center">
                <Box
                  onClick={() =>
                    window.open(
                      "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                    )
                  }
                  sx={{ cursor: "pointer" }}
                  component={"img"}
                  src={IMDA_SME_Large}
                  alt={"IMDA Pre-approved Solution Provider"}
                  maxWidth={"100%"}
                  margin={0}
                  width={"100%"}
                  mb={0}
                />
                <Box className={classes.SmeStyling}>
                  <ParagraphTypography
                    color="text.secondary"
                    fontSize={16}
                    sx={{ padding: 0, margin: 0 }}
                    textAlign="center"
                    className={classes.smeFooterText}
                  >
                    SMEs are eligible for up to 50% Productivity Solutions Grant
                    (PSG) support for the adoption of Pepper Cloud CRM solution,
                    a Pre-Approved Solution under the IMDA SMEs Go Digital
                    programme.
                  </ParagraphTypography>
                </Box>
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>

      <Box className={classes.CtoOffer} py={3}>
        <Container>
          <Box className={classes.offerCtoinner}>
            <HeaderTypography
              className={classes.bannerHeader}
              fontSize={{ sm: 36, xs: 34 }[width] || 44}
              fontWeight={600}
              lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
              mb={5}
              mt={4}
              overrideClassName
              textAlign="center"
              component="h2"
            >
              CTOaaS offers
            </HeaderTypography>
          </Box>
          <Box>
            <Grid
              alignItems="flex-start"
              className={classes.offerrSection}
              container
              justify="center"
              spacing={6}
            >
              <Grid item md={5} sm={12}>
                <Box className={classes.offerBlock}>
                  <Box
                    className={classes.offerIcon}
                    component={"img"}
                    height={80}
                    src={digitalAd}
                  />
                  <HeaderTypography
                    className={classes.offerHeader}
                    fontSize={{ sm: 24, xs: 24 }[width] || 18}
                    fontWeight={700}
                    textAlign="center"
                    component="h3"
                  >
                    Digital advisory
                  </HeaderTypography>
                  <ParagraphTypography
                    color="#000"
                    fontFamily="'SourceSansPro', sans-serif"
                    fontSize={16}
                    fontWeight={400}
                    lineHeight={"26px"}
                    mt={0}
                    textAlign="center"
                    maxWidth="350px"
                  >
                    {" "}
                    SMEs can engage consultants from appointed operators to
                    assess and identify unique digital needs.
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid item md={5} sm={12}>
                <Box className={classes.offerBlock}>
                  <Box
                    className={classes.offerIcon}
                    component={"img"}
                    height={80}
                    src={projectManagement}
                  />
                  <HeaderTypography
                    className={classes.offerHeader}
                    fontSize={{ sm: 24, xs: 24 }[width] || 18}
                    fontWeight={700}
                    textAlign="center"
                    component="h3"
                  >
                    Project management
                  </HeaderTypography>
                  <ParagraphTypography
                    color="#000"
                    fontFamily="'SourceSansPro', sans-serif"
                    fontSize={16}
                    fontWeight={400}
                    lineHeight={"26px"}
                    mt={0}
                    textAlign="center"
                    maxWidth="350px"
                  >
                    {" "}
                    Upon selecting a digital solution, SMEs can get assistance
                    from consultants in project implementation.
                  </ParagraphTypography>
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.writeToUsButton}>
              <Button
                color="secondary"
                size="large"
                variant="contained"
                component={Link}
                href="/contact"
              >
                Get Started
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.digitalSuccessBlock} py={6}>
        <Container>
          <Box>
            <HeaderTypography
              className={classes.bannerHeader}
              fontSize={{ sm: 36, xs: 34 }[width] || 44}
              fontWeight={600}
              lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
              mb={5}
              mt={4}
              overrideClassName
              textAlign="center"
              component="h2"
            >
              Empowering SMEs to achieve digital success
            </HeaderTypography>
            <ParagraphTypography
              color="#000"
              fontFamily="'SourceSansPro', sans-serif"
              fontSize={18}
              fontWeight={400}
              lineHeight={2}
              mb={6}
              mt={0}
              textAlign="center"
            >
              Pepper Cloud recognises the complex challenges encountered by SMEs
              while adopting a digital solution. Hence, we are committed to
              being your reliable partner in your growth and your digital
              transformation.
            </ParagraphTypography>
          </Box>
          <Box>
            <Grid
              alignItems="flex-start"
              className={classes.offerrSection}
              container
              justify="center"
              spacing={6}
            >
              <Grid item md={6} sm={12}>
                {pipeline.map((each, index) => (
                  <div key={each.title}>
                    <Box m={0} px={2}>
                      <Box
                        alignItems="center"
                        color="common.black"
                        display="flex"
                        justifyContent="space-between"
                        onClick={() =>
                          setActivePipeline(
                            index === activePipeline ? -1 : index
                          )
                        }
                        py={3}
                      >
                        <HeaderTypography
                          color="common.black"
                          fontSize={20}
                          fontWeight={700}
                          lineHeight={1}
                          m={0}
                          component="h2"
                          fontFamily="'SourceSansPro', sans-serif"
                        >
                          {each.title}
                        </HeaderTypography>
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          m={0}
                        >
                          {index !== activePipeline ? (
                            <AddIcon fontSize="small" m={0} />
                          ) : (
                            <RemoveIcon fontSize="small" m={0} />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={activePipeline === index}>
                        <ParagraphTypography
                          fontSize={16}
                          mt={0}
                          pr={10}
                          pt={2}
                          textAlign="justify"
                          borderColor="#7ad2e2"
                          // borderTop={1}
                          color="common.black"
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                      {index + 1 !== pipeline.length && (
                        <Divider className={classes.collapseDivider} light />
                      )}
                    </Box>
                  </div>
                ))}
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                className={classes.digitalSucessGridInner}
              >
                <Box
                  component={"img"}
                  className={classes.digitalSucessGridInnerImg}
                  src={digitalSuccess}
                  width={"100%"}
                  alt="CTO as a service for startups Singapore"
                  title="CTO as a service for startups Singapore"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className={classes.faqBox}>
        <Container>
          <Box>
            <Container>
              <HeaderTypography component="h2" fontSize={40} textAlign="center">
                CTOaaS - Frequently asked questions
              </HeaderTypography>
              {[
                {
                  title: "How do I know if I need CTO-as-a-Service?",
                  ans: (
                    <React.Fragment>
                      Consider opting for SME GoDigital’s CTO-as-a-Service if:
                      <ul>
                        <li>
                          You are facing challenges in implementing digital
                          strategies and technologies effectively.
                        </li>
                        <li>
                          You lack in-house expertise or resources to navigate
                          the complexities of digital transformation.
                        </li>
                        <li>
                          You are looking for guidance from technology experts
                          to make informed decisions.
                        </li>
                        <li>
                          You require tailored digitalisation resources and
                          support to optimise your operations.
                        </li>
                      </ul>
                      <a href="https://blog.peppercloud.com/ctoaas-make-digital-transformation-easier-for-sme/">
                        CTO-as-a-Service
                      </a>{" "}
                      can provide you with access to expert guidance, tailored
                      resources, and strategic planning to help you navigate
                      your digital transformation journey successfully.
                    </React.Fragment>
                  ),
                },
                {
                  title: "Is CTO-as-a-Service free?",
                  ans: "Yes! Eligible SMEs can take advantage of the first digital consultation and project management services at no cost. It's a great opportunity to explore the benefits of IMDA's CTO-as-a-Service without any financial commitment.",
                },
                {
                  title: "Who is eligible for CTOaaS?",
                  ans: (
                    <React.Fragment>
                      Any business entity that meets the following criteria will
                      be eligible:
                      <ul>
                        <li>Singapore-registered or incorporated SMEs.</li>
                        <li>
                          Companies with a minimum of 30% local shareholding.
                        </li>
                        <li>
                          Companies with a group annual sales turnover of less
                          than S$100 million, or an employment size of less than
                          200 workers.
                        </li>
                        <li>
                          Has not used the services and expertise provided by
                          CTO-as-a-Service previously.
                        </li>
                      </ul>
                    </React.Fragment>
                  ),
                },
                {
                  title: "What will be discussed during our engagements?",
                  ans: (
                    <React.Fragment>
                      During CTOaaS engagements, the following points will be
                      addressed:
                      <ul>
                        <li>
                          Business needs and priorities: Gain a deep
                          understanding of your organisation's specific needs
                          and priorities to ensure that digital solutions align
                          with your goals.
                        </li>
                        <li>
                          Tailored solutions and training roadmaps: Receive
                          customised digital solutions and training roadmaps
                          that are tailored to your unique requirements,
                          enabling you to maximise the benefits of digital
                          transformation.
                        </li>
                        <li>
                          Evaluation of IMDA initiatives: Explore digital
                          solutions available under IMDA initiatives, such as
                          the PSG Grant, and assess how they can effectively
                          address your specific needs and concerns.
                        </li>
                        <li>
                          Cybersecurity and data protection: Incorporate
                          essential cybersecurity measures and data usage
                          protection requirements into your digitalisation
                          efforts to safeguard your organisation's digital
                          assets and ensure compliance with relevant
                          regulations.
                        </li>
                        <li>
                          Implementation planning: Creating a roadmap for the
                          successful implementation of digital projects,
                          including timelines, resource allocation, and project
                          management strategies.
                        </li>
                        <li>
                          Training and upskilling: Identifying training needs
                          and recommending programs to enhance the digital
                          skills and knowledge of the organisation's workforce.
                        </li>
                      </ul>
                      The objective of CTOaaS is to provide you with
                      comprehensive support and guidance, ensuring that your
                      digital transformation journey is aligned with your
                      business objectives while addressing critical
                      considerations such as cybersecurity and data protection.
                    </React.Fragment>
                  ),
                },
              ].map((each, index) => (
                <div key={each.title}>
                  <Box
                    className={classes.faqTitle}
                    m={0}
                    onClick={handleClickCollapse(index)}
                  >
                    <Box component="h3" fontSize={22} fontWeight={600} my={2}>
                      <HeaderTypography
                        component="h2"
                        fontSize={18}
                        fontFamily="'SourceSansPro', sans-serif"
                      >
                        {each.title}
                      </HeaderTypography>
                    </Box>
                    <div>
                      <IconButton
                        aria-label="Expand or Collapse"
                        className={classes.expendButtonFaq}
                      >
                        {index !== open ? (
                          <AddIcon color="#74777a" fontSize="small" />
                        ) : (
                          <RemoveIcon color="#74777a" fontSize="small" />
                        )}
                      </IconButton>
                    </div>
                  </Box>
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <p className={classes.faqAnswer} fontSize={22}>
                      {each.ans}
                    </p>
                  </Collapse>
                  {index + 1 !== pipeline.length && (
                    <Divider className={classes.collapseDivider} light />
                  )}
                </div>
              ))}
            </Container>
          </Box>
        </Container>
      </Box>
      {/* Book a free demo */}
      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Box alignItems="center" display="flex" justifyContent="center">
          <Container>
            <HeaderTypography
              color="common.white"
              fontSize={35}
              my={0}
              textAlign="center"
              component="h2"
            >
              Start your digitalisation journey today with CTOaaS
            </HeaderTypography>
          </Container>
        </Box>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            href="/contact"
            size="large"
            variant="contained"
          >
            Talk to an Expert
          </WhiteButton>
        </Box>
      </Box>
      {/* footer */}
    </React.Fragment>
  );
};

export default CtoAsAService;
